<template>
  <b-row>
    <b-col cols="12">
      <b-card class="wrapper store-order-statement" v-if="!isLoading">
      <vue-good-table
        mode="remote"
        :columns="columns"
        :totalRows="totalRows"
        :rows="departments"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        @on-sort-change="onSortChange"
        @on-search="onSearch"
        :search-options="{
        enabled: false,
        placeholder: $t('Search this table'),  
      }"
        :select-options="{ 
          enabled: false ,
          clearSelectionText: '',
        }"
        @on-selected-rows-change="selectionChanged"
        :pagination-options="{
        enabled: true,
        mode: 'records',
        nextLabel: 'next',
        prevLabel: 'prev',
      }"
        styleClass="vgt-table bordered striped table-hover"
      >
        <div slot="selected-row-actions">
          <button class="btn btn-danger btn-sm" @click="delete_by_selected()">{{$t('Del')}}</button>
        </div>
        <div slot="table-actions">
          <b-button
            @click="New_Department()"
            class="btn-rounded"
            variant="btn btn-primary btn-icon mr-1"
          >
            {{$t('Add')}}
          </b-button>
        </div>

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'actions'">
            <a @click="Edit_Department(props.row)" class="cursor-pointer" title="Edit" v-b-tooltip.hover>
              <img :src="$helpers.getIcons('edit')" alt="" class="cursor-pointer action-icon-grid">
            </a>
            <a title="Delete" v-b-tooltip.hover class="cursor-pointer" @click="Remove_Department(props.row.id)">
              <img :src="$helpers.getIcons('delete')" alt="" class="cursor-pointer action-icon-grid">
            </a>
          </span>
        </template>
      </vue-good-table>
    </b-card>

    <validation-observer ref="Create_Department">
      <b-modal hide-footer size="md" v-model="departmentModal" :title="editmode?$t('Edit'):$t('Add')">
        <b-form @submit.prevent="Submit_Department">
          <b-row>
            <!-- Name -->
            <b-col md="12">
              <validation-provider
                name="department"
                :rules="{ required: true}"
                v-slot="validationContext"
              >
                <b-form-group :label="$t('Department') + ' ' + '*'">
                  <b-form-input
                    :placeholder="$t('Department Name')"
                    :state="getValidationState(validationContext)"
                    aria-describedby="department-feedback"
                    label="department"
                    v-model="department.department"
                  ></b-form-input>
                  <b-form-invalid-feedback id="department-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>


              <!-- Department_Head -->
              <b-col md="12">
                <b-form-group :label="$t('Department Head')">
                  <v-select
                    v-model="department.department_head"
                    @input="Selected_Employee"
                    :placeholder="$t('Choose Department Head')"
                    :reduce="label => label.value"
                    :options="employees.map(employees => ({label: employees.username, value: employees.id}))"
                  />
                </b-form-group>
            </b-col>

            <b-col md="12" class="mt-3">
                <b-button variant="primary" type="submit"  :disabled="SubmitProcessing"><i class="i-Yes me-2 font-weight-bold"></i> {{$t('submit')}}</b-button>
                  <div v-once class="typo__p" v-if="SubmitProcessing">
                    <div class="spinner sm spinner-primary mt-3"></div>
                  </div>
            </b-col>

          </b-row>
        </b-form>
      </b-modal>

    </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import Vue from 'vue'
import axios from 'axios'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'
const Fire = new Vue();
const apiUrl = `${jwtDefaultConfig.apiEndpoint}/hrm`;
import { hideLoader, showErrorNotification, showLoader, showSuccessNotification} from '@/@core/comp-functions/ui/app'

export default {
  metaInfo: {
    title: "Department"
  },
  components: {
    VueGoodTable,
  },
  data() {
    return {
      isLoading: true,
      SubmitProcessing: false,
      serverParams: {
        columnFilters: {},
        sort: {
          field: "id",
          type: "desc"
        },
        page: 1,
        perPage: 10
      },
      selectedIds: [],
      totalRows: "",
      search: "",
      limit: "10",
      editmode: false,
      departments: {},
      employees: [],
      companies: [],
      department: {
        department: "",
        department_head: "",
      },
      departmentModal: false
    };
  },

  computed: {
    columns() {
      return [
        {
          label: this.$t("Department"),
          field: "department",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Department Head"),
          field: "employee_head",
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          label: this.$t("Action"),
          field: "actions",
          html: true,
          tdClass: "text-center",
          thClass: "text-center",
          sortable: false
        }
      ];
    }
  },

  methods: {
    //---- update Params Table
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    //---- Event Page Change
    onPageChange({ currentPage }) {
      if (this.serverParams.page !== currentPage) {
        this.updateParams({ page: currentPage });
        this.Get_Department(currentPage);
      }
    },

    //---- Event Per Page Change
    onPerPageChange({ currentPerPage }) {
      if (this.limit !== currentPerPage) {
        this.limit = currentPerPage;
        this.updateParams({ page: 1, perPage: currentPerPage });
        this.Get_Department(1);
      }
    },

    //---- Event Select Rows
    selectionChanged({ selectedRows }) {
      this.selectedIds = [];
      selectedRows.forEach((row, index) => {
        this.selectedIds.push(row.id);
      });
    },

    //---- Event Sort Change

    onSortChange(params) {
      let field = "";
      field = params[0].field;
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      });
      this.Get_Department(this.serverParams.page);
    },

    //---- Event Search
    onSearch(value) {
      this.search = value.searchTerm;
      this.Get_Department(this.serverParams.page);
    },

    //---- Validation State Form
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },

    //------------- Submit Validation Create & Edit department
    Submit_Department() {
      this.$refs.Create_Department.validate().then(success => {
        if (!success) {
          showErrorNotification(this, "Please fill the for correctly");
        } else {
          if (!this.editmode) {
            this.Create_Department();
          } else {
            this.Update_Department();
          }
        }
      });
    },

    //------------------------------ Modal (create department) -------------------------------\\
    New_Department() {
      this.reset_Form();
      this.editmode = false;
      this.Get_Data_Create();
      this.departmentModal = true
    },

    //------------------------------ Modal (Update department) -------------------------------\\
    Edit_Department(department) {
      this.Get_Department(this.serverParams.page);
      this.reset_Form();
      this.Get_Data_Edit(department.id);
      this.Get_employees_by_company();
      this.department = department;
      this.editmode = true;
      this.departmentModal = true
    },

      Selected_Company() {
          this.employees = [];
          this.department.department_head = "";
          this.Get_employees_by_company();
      },

      Selected_Employee(value) {
          if (value === null) {
              this.department.department_head = "";
          }
      },

      //---------------------- Get_employees_by_company ------------------------------\\
      
      Get_employees_by_company(value) {
          axios
          .get(apiUrl+"/core/get_employees_by_company")
          .then(({ data }) => (this.employees = data));
      },

       //---------------------- Get_Data_Create  ------------------------------\\
          Get_Data_Create() {
            axios
                .get(apiUrl+"/departments/create")
                .then(response => {
                    this.companies   = response.data.companies;
                })
                .catch(error => {
                    
                });
        },

        //---------------------- Get_Data_Edit  ------------------------------\\
        Get_Data_Edit(id) {
          axios
              .get(apiUrl+"/departments/"+id+"/edit")
              .then(response => {
                  this.companies   = response.data.companies;
              })
              .catch(error => {
                  
              });
      },


    //--------------------------Get ALL department ---------------------------\\

    Get_Department(page) {
      // Start the progress bar.
      axios
        .get(apiUrl+
          "/departments?page=" +
            page +
            "&SortField=" +
            this.serverParams.sort.field +
            "&SortType=" +
            this.serverParams.sort.type +
            "&search=" +
            this.search +
            "&limit=" +
            this.limit
        )
        .then(response => {
          this.totalRows = response.data.totalRows;
          this.departments = response.data.departments;

          // Complete the animation of theprogress bar.
          this.isLoading = false;
        })
        .catch(response => {
          // Complete the animation of theprogress bar.
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
    },

    //------------------------------- Create department ------------------------\\
    Create_Department() {
      
      this.SubmitProcessing = true;
      axios
        .post(apiUrl+"/departments", {
          department: this.department.department,
          department_head: this.department.department_head,
          
        })
        .then(response => {
          this.SubmitProcessing = false;
          Fire.$emit("Event_Department");
          showSuccessNotification(this, 'Created successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- Update department ------------------------\\
    Update_Department() {
      this.SubmitProcessing = true;
      axios
        .put(apiUrl+"/departments/" + this.department.id, {
          department: this.department.department,
          department_head: this.department.department_head,
        })
        .then(response => {
          this.SubmitProcessing = false;
          Fire.$emit("Event_Department");
          showSuccessNotification(this, 'Updated successfully!');
        })
        .catch(error => {
          this.SubmitProcessing = false;
          showErrorNotification(this, "Invalid Data")
        });
    },

    //------------------------------- reset Form ------------------------\\
    reset_Form() {
     this.department = {
          id: "",
          department: "",
          department_head:"",
      };
    },

    //------------------------------- Delete department ------------------------\\
    Remove_Department(id) {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .delete(apiUrl+"/departments/" + id)
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');
              Fire.$emit("Delete_Department");
            })
            .catch(() => {
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    },

    //---- Delete department by selection

    delete_by_selected() {
      this.$swal({
        text: '',
        title: this.$t('Please confirm you want to delete this item?'),
        iconHtml: this.$helpers.swalIcon('alert.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          // Start the progress bar.
          axios
            .post(apiUrl+"/departments/delete/by_selection", {
              selectedIds: this.selectedIds
            })
            .then(() => {
              showSuccessNotification(this, 'Deleted successfully!');

              Fire.$emit("Delete_Department");
            })
            .catch(() => {
              // Complete the animation of theprogress bar.
              showErrorNotification(this, "Something went wrong while deleting item!");
            });
        }
      });
    }
  },

  //----------------------------- Created function-------------------\\

  created: function() {
    this.Get_Department(1);

    Fire.$on("Event_Department", () => {
      setTimeout(() => {
        this.Get_Department(this.serverParams.page);
        this.departmentModal = false
      }, 500);
    });

    Fire.$on("Delete_Department", () => {
      setTimeout(() => {
        this.Get_Department(this.serverParams.page);
      }, 500);
    });
  }
}
</script>

<style scoped>
.wrap-order-transaction-buttons .btn {
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 767px) {
  .wrap-order-transaction-buttons {
    display: none;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
